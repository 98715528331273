module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"modalProps":{"portalClassName":"bpModal","overlayClassName":"bpModal__Overlay","className":"bpModal__Content","bodyOpenClassName":"bpModal__Body--open","htmlOpenClassName":"bpModal__Html--open"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-49866958-5","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"talkPost":"/home/runner/work/paratriennale/paratriennale/loveletter/src/templates/talk-page-layout.js","worksPost":"/home/runner/work/paratriennale/paratriennale/loveletter/src/templates/works-page-layout.js","default":"/home/runner/work/paratriennale/paratriennale/loveletter/src/templates/default-page-layout.js"},"remarkPlugins":[null],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1280,"linkImagesToOriginal":false,"withWebp":true,"showCaptions":true,"quality":95}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"bookproject","short_name":"bookproject","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"standalone","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"cf18fb41c0d8ddc7d2ac2cb802a3eb69"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
